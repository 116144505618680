
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

interface Course {
  id: string;
  subject: { _id: string; name: string };
  module: string;
  title: string;
  desc: string;
  createdAt: string;
  updatedAt: string;
  spec?: boolean;
}

export default defineComponent({
  name: "courses-page",
  components: {},
  setup() {
    const { t } = useI18n();
    const loading = ref(true);

    const store = useStore();
    const currentStudent = store.getters.currentStudent;

    const courses: Array<Course> = [];

    ApiService.get(`/lms/course/parent/${currentStudent._id}`)
      .then(({ data }) => {
        console.log(data);
        data.forEach((course) => {
          courses.push({
            id: course._id,
            subject: { name: course.subject.name, _id: course.subject._id },
            module: course.module,
            desc: course.desc,
            title: course.title,
            createdAt: course.createdAt,
            updatedAt: course.updatedAt,
            spec: course.spec,
          });
        });
        for (const item of courses) {
          filteredCourses.value.push(item);
        }
        loading.value = false;
      })
      .catch((e) => console.log(e));

    const filteredCourses = ref<Array<Course>>([]);
    const search = ref<string>("");

    const getFileIconName = (file: string): string => {
      const type = file.split(".")[1];
      if (
        ["pdf", "png", "jpg", "ppt", "xls", "txt", "gif", "doc"].includes(type)
      )
        return type;
      return "file";
    };

    const getShortFileName = (file: string): string => {
      if (file.length > 20)
        return "..." + file.substring(file.length - 15, file.length);
      return file;
    };
    const searchCourses = () => {
      filteredCourses.value = courses.filter((course) => {
        const toSearch = search.value.toLowerCase();
        return (
          course.title.toLowerCase().includes(toSearch) ||
          course.subject.name.toLowerCase().includes(toSearch)
        );
      });
    };

    onMounted(() => {
      setCurrentPageTitle(t("courses"));
    });

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredCourses.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredCourses.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      getFileIconName,
      getShortFileName,
      moment,
      search,
      searchCourses,
      filteredCourses,
      loading,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
    };
  },
});
